import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import * as PR from "../../prime-modules/index";
import { useNavigate, Link } from 'react-router-dom';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPriceFilters, getPromoCodesList } from "../../services/api";
import { orderStatuses, paymentGatways, formatDateTime, formatPromoCode } from "../../utils/reuse";

const OrdersList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef();
    const calendarRef = useRef(null);
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const currentDate = new Date();
    const [providerList, setProviderList] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [promoCodeOptions, setPromoCodeOptions] = useState([])
    const toggleFilter = () => setFilterOpen(!filterOpen);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(() => {
        getPriceFilters(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const providers = response.data?.providers?.length > 0 ? response.data?.providers.map(provider => ({ label: provider, value: provider })).sort((a, b) => a.label.localeCompare(b.label)) : [];
                setProviderList(providers);
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
            }
        })
    }, [dispatch, headers]);

    const getPromoCodes = useCallback(async (request = { startTs: '', endTs: '', status: '', type: 'percent,fixed' }) => {
        let getResponse = response => {
            if (response.result === "SUCCESS") {
                const promoCodesList = response.data
                    ? [...new Set(response.data.map(data => data.promoCode).filter(code => code && code.trim() !== ''))]
                    .sort((a, b) => a.localeCompare(b))
                    : [];
                setPromoCodeOptions(promoCodesList.map(code => ({ label: code, value: code })));
            }
        };
        getPromoCodesList(request, headers, dispatch, getResponse);
    }, [dispatch, headers]);    

    useEffect(() => {
        getPromoCodes();
    }, [getPromoCodes]);

    const validationSchema = Yup.object().shape({
        search: Yup.string().trim(),
    })

    const resetOrderForm = () => {
        props.setKeywordSearch('')
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        setFiltersVisible(false)
        const formData = new FormData(e.target);
        const formValues = Object.fromEntries(formData.entries())
        props.setCustomer(trimFormData(formValues.customer));
        props.setAffiliateUser(trimFormData(formValues.searchAffiliateUser));
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
        setFilterCount(getAppliedFiltersCount());
    }

    const handleFilterReset = () => {
        props.setCustomer('');
        props.setAffiliateUser('');
        props.setPurchaseDate(props.nVal);
        props.setPurchaseDateFrom(props.nVal);
        props.setPurchaseDateTo(props.nVal);
        props.setPaymentGateway([]);
        props.setPaymentStatus([]);
        props.setOrderStatus([]);
        props.setProviderName([]);
        props.setPromoCode('')
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
        setFilterCount(0);
    }

    const handleSubmit = (formData) => {
        props.setKeywordSearch(trimFormData(formData.search));
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setSearchTrigger(Date.now())
        props.setOrdersData(true);
    }

    const trimFormData = (val) => {
        return val.trim();
    }

    const formik = useFormik({
        initialValues: {
            search: props.keywordSearch,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })

    const handleSelectedDate = (e) => {
        if (e.value === props.nVal) {
            props.setPurchaseDate(props.nVal);
            props.setPurchaseDateFrom(props.nVal);
            props.setPurchaseDateTo(props.nVal);
        } else {
            const date = e.value;
            const fromDate = utils.modifyDate(date[0]);
            const toDate = (date[1] === props.nVal) ? fromDate : utils.modifyDate(date[1]);
            props.setPurchaseDate(date);
            props.setPurchaseDateFrom(fromDate);
            props.setPurchaseDateTo(toDate);

            // close the calendar if from and to date is selected
            if (date?.length > 1 && date[1] != null) {
                calendarRef.current.hide()
            }
        }
        props.setOrdersData(false);
    }

    const handleProviderChange = (e, provider) => {
        const isChecked = e.target.checked;
        let updatedProviders = [...props.providerName];
    
        if (isChecked) {
            updatedProviders.push(provider);
        } else {
            updatedProviders = updatedProviders.filter(p => p !== provider);
        }
    
        props.setProviderName(updatedProviders);
        props.setOrdersData(false);
    }; 
    
    const handleSelectedOrderStatus = (val) => {
        props.setOrderStatus(val);
        props.setOrdersData(false);
    }

    const handleSelectedPaymentGateway = (val) => {
        props.setPaymentGateway(val);
        props.setOrdersData(false);
    }

    const statusBgColor = (status) => {
        const statusVal = orderStatusList?.filter(val => val.value === status)[0]?.label;
        return (
            <p>
                <span
                    className={`status ${status?.toLowerCase()}`}
                >{statusVal ?? status ?? 'N/A'}</span>
            </p>
        );
    };
    const usenameTemplate = rowData => {
        const userCustomers = '/users?email=' + encodeURIComponent(rowData.email);
       return (
            props.screen === 'user-orders'
                ? rowData.username
                : <Link to={userCustomers} className="underline">{rowData.username}</Link>
        )
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Order ID has been copied to clipboard.' });
        }).catch(err => {
            toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Failed to copy order ID.' });
        });
    };

    const geteSIMsDescription = (esim) => {
        const countryName = esim?.countries?.length ? esim?.countries[0]?.country?.name?.split("(")[0] : '';
        const description = countryName && esim.dataAmountForDisplay ? `${countryName} - ${esim.dataAmountForDisplay}` : esim.description;
        return description;
    }

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul mobile-col-ul">
                            <li className="customer">
                                <div className="column-label">Customer</div>
                                <div className="column-data">{usenameTemplate(rowData)}</div>
                            </li>
                            <li className="orderId">
                                <div className="column-label">Order ID</div>
                                <div className="column-data">{rowData.orderId.substring(0, 8)}</div>
                            </li>
                            <li className="bundleName">
                                <div className="column-label">Bundle Name</div>
                                <div className="column-data" >{geteSIMsDescription(rowData?.dataPlans.length && rowData.dataPlans[0])}</div>
                            </li>
                            <li className="price">
                                <div className="column-label">Total Price</div>
                                    <div className="column-data">
                                        {rowData.priceBeforeDiscount > 0 && (rowData.priceBeforeDiscount !== rowData.totalOrderAmount) && (
                                                <s className="red-color">
                                                    {utils.setPrice(rowData.priceBeforeDiscount)}
                                                </s>
                                        )}
                                        <div>
                                            {utils.setPrice(rowData.totalOrderAmount)}
                                        </div>
                                    </div>
                            </li>
                            <li className="promoCode">
                                <div className="column-label">Promocode</div>
                                <div className="column-data">
                                    {rowData.promoCode && (
                                        <>
                                            <span 
                                                className={`promo-tooltip${rowData.orderId}`}
                                                data-pr-tooltip={rowData.discountValue ? `Discount Value: ${rowData.discountValue}` : rowData.paymentGateway === "prepaid" ? "Prepaid Voucher" : "-"}
                                                data-pr-position="bottom"
                                            >
                                            {formatPromoCode(rowData?.promoCode, rowData.paymentGateway === 'prepaid') || " - "}
                                            </span>
                                            <PR.Tooltip target={`.promo-tooltip${rowData.orderId}`} className="promocode-tooltip" />
                                        </>
                                    )}
                                </div>
                            </li>
                            <li className="providerName">
                                <div className="column-label">Provider</div>
                                <div className="column-data">{rowData.providerName}</div>
                            </li>
                            <li className="createdTs">
                                <div className="column-label">Date & time</div>
                                <div className="column-data">{formatDateTime(rowData.createdTs)}</div>
                            </li>
                            <li className="paymentGateway">
                                <div className="column-label">Payment Method</div>
                                <div className="column-data">{rowData.paymentGateway ? utils.firstLetterCapital(rowData.paymentGateway) : "-"}</div>
                            </li>
                            <li className="paymentStatus">
                                <div className="column-label">Payment Status</div>
                                <div className="column-data">{statusBgColor(rowData?.paymentStatus)}</div>
                            </li>
                            <li className="orderStatus">
                                <div className="column-label">Order Status</div>
                                <div className="column-data">{statusBgColor(rowData?.orderStatus)}</div>
                            </li>
                            <li className="affiliateUser">
                                <div className="column-label">Affiliate Name</div>
                                <div className="column-data">{rowData.affiliateUser ? rowData.affiliateUser : "-"}</div>
                            </li>
                            <li className="orderActions">
                                <div className="column-label">Actions</div>
                                <div className="column-data flex">
                                    <div className="copyOrderId" onClick={() => copyToClipboard(rowData.orderId)}>
                                        <PR.Button
                                            icon="pi pi-copy"
                                            className="p-button-text icon-button"
                                            tooltip="Copy Order ID"
                                            tooltipOptions={{ position: 'top' }}
                                            style={{ color: 'red', fontSize: '1.5rem' }}
                                        />
                                    </div>
                                    <div className="orderDetailArrow" onClick={() => navigate('/' + props.screen + '/details/' + rowData.orderId)}
                                    >
                                        <PR.Button
                                            icon="pi pi-chevron-right"
                                            className="p-button-text icon-button"
                                            tooltip="View Order Details"
                                            tooltipOptions={{ position: 'top' }}
                                            style={{ color: 'red', fontSize: '1.5rem' }}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const paginate = (currentPage) => {
        props.setPaging(false);
        props.setPage(currentPage);
        const offsetVal = (currentPage - 1) * props.pageCount;
        const limitVal = props.pageCount + 1;
        props.setOffset(offsetVal);
        props.setLimit(limitVal);
        props.setOrdersData(true);
        props.setListingType('list');
    };

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            props.setSortOrder(-1);
            props.setSortField(value.substring(1, value.length));
            props.setSortKey(value);
        } else {
            props.setSortOrder(1);
            props.setSortField(value);
            props.setSortKey(value);
        }
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
    };

    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : [])
            .map(customer => customer.trim())
            .sort((a, b) => a.localeCompare(b));
    };

    const handlePromocodeChange = (val) => {
        const selectedCodes = val || '';
        props.setPromoCode(selectedCodes);
        props.setOrdersData(false);
    };
    

    const ordersDataHeader = (field, label, selector, sortable = true) => {
        return (
            sortable ?
                (props.sortField !== field) ?
                    <>
                        <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                    </> :
                    <>
                        {(props.sortKey === field) ?
                            <>
                                <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                            </> :
                            <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                        }
                    </>
                :
                <li className={selector} style={{ pointerEvents: 'none' }}>
                    <span>{label}</span>
                </li>
        );
    }

    const orderStatusList = orderStatuses;
    const paymentGatewayOptions = paymentGatways;

    const handleCustomer = (e) => {
        props.setCustomer(e.target.value)
        formik.handleChange(e)
        props.setOrdersData(false);
    }

    const handleKeywordSearch = (e) => {
        props.setKeywordSearch(e.target.value)
        formik.handleChange(e)
        props.setOrdersData(false);
    }

    const handleAffiliateName = (e) => {
        props.setAffiliateUser(e.target.value)
        formik.handleChange(e)
        props.setOrdersData(false);
    }

    const getAppliedFiltersCount = () => {
        let count = 0;
    
        // Count selected checkboxes
        count += props.providerName.length;        
        count += props.orderStatus.length;         
        count += props.paymentGateway.length;      
    
        // Count non-checkbox filters
        if (props.customer) count++;
        if (props.purchaseDate) count++;          
        if (props.affiliateUser) count++;         
        if (props.promoCode) count++;             
    
        return count;
    };
    
    return (
        <section className="admin-orderlist-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="heading-sec align-items-center justify-content-between">
                        <div className="flex align-items-center justify-content-between mb-5 mobile-margin">
                            <div className="right flex align-items-center ">
                                <h2>Orders</h2>
                                <div className="breadcrumb-section">
                                    <PR.BreadCrumb model={props.items} home={props.home} />
                                </div>
                            </div>
                            <div className="right-section flex align-items-right gap-3" >
                                <PR.Tooltip target=".export-tooltip" content={!props.purchaseDate ? 'Select Date Range to Export CSV' : undefined} position="top" showOnDisabled />
                                <PR.Button label="Export CSV" className="exportBtn export-tooltip" icon="pi pi-file-export" iconPos="left" onClick={() => props.setListingType('export')}
                                     disabled={!(filterCount > 0 && props.purchaseDate) || props?.loading}
                                />
                                <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                    <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                </PR.Button>
                            </div>
                        </div>
                        <div className={`filter-right ${filterOpen ? "mobile-filter-section" : ""}`}>
                            <form autoComplete="off" onSubmit={formik.handleSubmit} className="email-orderid-iccid">
                                <div className='flex align-items-center mobile-flex'>
                                    <div className="users-search-input">
                                        <span>
                                            <PR.InputText placeholder="Search by Customer Email, Order ID or ICCID" name="search" onChange={handleKeywordSearch} onBlur={formik.handleBlur} value={props.keywordSearch} autoComplete="off" />
                                        </span>
                                    </div>
                                  
                                    <div className="users-search-input last-buttons-block">
                                        <span>
                                            <PR.Button label="Search" type='submit' className="searchBtn" />
                                            <PR.Button label="Reset" type='reset' className="resetBtn ml-1" onClick={resetOrderForm} />
                                        </span>
                                    </div>
                                </div>
                            </form>
                            <PR.Button 
                                icon="pi pi-filter" 
                                label={filterCount > 0 ? <>Filter <span className="count">{filterCount}</span></> : "Filter"} 
                                className="exportBtn filter-btn" 
                                onClick={() => setFiltersVisible(true)} 
                                disabled={props.loading} 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="users-data-table card">
                <div className="card datatable-scroll">
                    <div className="custom-table">
                        <div className="custom-table-header">
                            <div className="table-grid">
                                <ul className="col-ul sorting-li">
                                    {ordersDataHeader('name', 'Customer', 'customer')}
                                    {ordersDataHeader('orderId', 'Order ID', 'orderId')}
                                    {ordersDataHeader('bundleName', 'Bundle Name', 'bundleName', false)}
                                    {ordersDataHeader('totalOrderAmount', 'Total Price', 'price')}
                                    {ordersDataHeader('promoCode', 'Promocode', 'promoCode')}
                                    {ordersDataHeader('providerName', 'Provider', 'providerName')}
                                    {ordersDataHeader('createdTs', 'Date & time', 'purchasedOn')}
                                    {ordersDataHeader('paymentGateway', 'Payment Method', 'paymentGateway')}
                                    {ordersDataHeader('paymentStatus', 'Payment Status', 'paymentStatus')}
                                    {ordersDataHeader('orderStatus', 'Order Status', 'orderStatus')}
                                    {ordersDataHeader('affiliateName', 'Affiliate Name', 'affiliateUser')}
                                    {ordersDataHeader('actions', 'Actions', 'actions', false)}
                                </ul>
                            </div>
                        </div>
                        <PR.DataView
                            loading={props.loading}
                            value={props.orders}
                            itemTemplate={itemTemplate}
                            emptyMessage={"No Results Found"}
                        />
                    </div>
                </div>
            </div>

             <PR.Dialog header="Filter Options" visible={filtersVisible} onHide={() => { if (!filtersVisible) return; setFiltersVisible(false); }} draggable={false} blockScroll={true} className="filter-dialog order-filters-dialog">
                    <form autoComplete="off" className="mt-0" onSubmit={handleFilterSubmit}>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="customer" className="title-label">Customer Name</label>
                                    <PR.InputText placeholder="Filter by customer name" name="customer" inputId="customer" onChange={handleCustomer} onBlur={formik.handleBlur} value={props.customer} autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="searchPurchaseDate" className="title-label">Date Range</label>
                                    <PR.Calendar panelClassName="datte-range-calendar" inputId="searchPurchaseDate" value={props.purchaseDate} onChange={handleSelectedDate} selectionMode="range" readOnlyInput placeholder="Select date range" name="searchPurchaseDate" dateFormat="dd-mm-yy" maxDate={currentDate} showIcon showButtonBar numberOfMonths={2} ref={calendarRef} />
                                </div>
                            </div>
                        </div>

                        <div className="grid mt-1">
                            <div className="col-12 md:col-6">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="searchAffiliateUser" className="title-label">Affiliate Name</label>
                                    <PR.Dropdown filter resetFilterOnHide showClear placeholder="Select affiliate name" inputId="searchAffiliateUser" name="searchAffiliateUser" options={handleAffiliateOptions(props.affiliateNames)} onChange={handleAffiliateName} onBlur={formik.handleBlur} value={props.affiliateUser || ''} autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="searchPromocode" className="title-label">Promo code</label>
                                    <PR.Dropdown filter resetFilterOnHide showClear value={props.promoCode || ''} inputId="searchPromocode" options={promoCodeOptions} onChange={(e) => handlePromocodeChange(e.value)} name="searchPromocode" optionLabel="label" placeholder="Select promo code"/>
                                </div>
                            </div>
                        </div>
                        
                        {providerList.length > 0 && 
                        <div className="card mt-4">
                            <label htmlFor="username" className="title-label">Provider</label>
                            <div className="flex gap-3 flex-wrap mt-3">
                                {
                                    providerList.map((provider, index) =>
                                        <div className="flex mr-2" key={index}>
                                            <PR.Checkbox inputId={`provider${index}`} checked={props.providerName.includes(provider.value)} onChange={(e) => handleProviderChange(e, provider.value)}></PR.Checkbox>
                                            <label htmlFor={`provider${index}`} className="ml-1">{provider.label}</label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        }

                        {orderStatusList.length > 0 && <div className="card mt-4">
                            <label htmlFor="username" className="title-label">Order Status</label>
                            <div className="flex gap-3 flex-wrap mt-3">
                                {
                                    orderStatusList.map((status) => (
                                        <div className="flex mr-2" key={status.value}>
                                            <PR.Checkbox inputId={status.value} value={status.value} checked={props.orderStatus.includes(status.value)} onChange={(e) => handleSelectedOrderStatus(e.checked ? [...props.orderStatus, e.value] : props.orderStatus.filter(val => val !== e.value))}></PR.Checkbox>
                                            <label htmlFor={status.value} className="ml-1">{status.label}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        }

                        {paymentGatewayOptions.length > 0 && <div className="card mt-4">
                            <label htmlFor="username" className="title-label">Payment Method</label>
                            <div className="flex gap-3 amount-data-options flex-wrap mt-3">
                                {
                                    paymentGatewayOptions.map((gateway) => (
                                        <div className="flex mr-2" key={gateway.value}>
                                            <PR.Checkbox inputId={gateway.value} value={gateway.value} checked={props.paymentGateway.includes(gateway.value)} onChange={(e) => handleSelectedPaymentGateway(e.checked ? [...props.paymentGateway, e.value] : props.paymentGateway.filter(val => val !== e.value))}></PR.Checkbox>
                                            <label htmlFor={gateway.value} className="ml-1">{gateway.label}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        }
                        <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                            <PR.Button label="Reset" type="button" className="confirm-button reset-btn min-width" onClick={handleFilterReset}/>
                            <PR.Button label="Apply Filters" type="submit" className="confirm-button min-width"/>
                        </div>
                    </form>
            </PR.Dialog>

            {props.orders.length > 0 ? (
                <div className="pagination">
                    <button
                        type="button"
                        onClick={() => paginate(props.page - 1)}
                        className={props.paging ? props.page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                    >
                        {globalConfig.pagination_Previous}
                    </button>
                    <span className="active"> {props.page} </span>
                    <button
                        type="button"
                        onClick={() => paginate(props.page + 1)}
                        className={props.paging ? props.last ? "disabled" : "pagination-button" : "disabled"}
                    >
                        {globalConfig.pagination_Next}
                    </button>
                </div>
            ) : (
                <></>
            )}
            <PR.Toast ref={toast} position='top-right' />
        </section>
    );
}

export default OrdersList;