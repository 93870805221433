import React, { useCallback } from 'react';
import { useState, useMemo, useEffect } from 'react';
import * as PR from "../../../prime-modules/index";
import { useSelector } from 'react-redux';
import "./FAQ.scss";
import { getLanguageTranslates, uploadTranslations } from '../../../services/api';
import { useDispatch } from 'react-redux';
import { languageConfig } from '../header/MenuConfig';
import FaqItem from './FaqItem';

const FAQ = () => {
    const faqKey = 'FAQNEW-';
    const appName = process.env?.REACT_APP_NAME;
     const languages = languageConfig[appName] ? languageConfig[appName] : [
        { name: "English", code: "en" },
        { name: "Dutch", code: "nl" },
        { name: "Portuguese", code: "pt" },
        { name: "Spanish", code: "es" },
        { name: "Tagalog", code: "tl" },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

    const toast = React.useRef(null)
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [assetsnew, setAssetsnew] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const [addTabStatus, setAddTabStatus] = useState(false)
    const [searchValue, setSearchValue] = useState("");

    const [category, setCategory] = useState({ [faqKey]: { name: "" } });
    const [categoryKey, setCategoryKey] = useState(faqKey);
    const [allCategories, setAllCategories] = useState({});
    const [allCategoriesKeys, setAllCategoriesKeys] = useState([]);
    const [tabId, setTabId] = useState('FAQNEW-001');
    const [editTabId, setEditTabId] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [selectedLayout, setSelectedLayout] = useState({});
    const [layoutOption] = useState([
        { name: "Regular", code: "Regular" },
        { name: "Custom Tab", code: "CustomTab" },
    ]);
    const [addDisable, setAddDisable] = useState(false)
    const [editKey, setEditKey] = useState("")
    const [loading, setLoading] = useState(false);
    const [deleteTabModal, setDeleteTabModal] = useState("");
    const [deleteTabId, setDeleteTabId] = useState("");
    const allTranslateHandler = useCallback(() => {
        setAddDisable(false);
        setEditKey("");
        const getResponse = (response) => {
            setLoading(false);
            if (response.result === 'SUCCESS') {
                const assetData = response.data;
                setAssetsnew(assetData['faq-new'] ?? {});
            } else if (response.result === "FAILED" && response.error) {
                setLoading(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        getLanguageTranslates(selectedLanguage?.code, headers, dispatch, getResponse)
    }, [dispatch, headers, selectedLanguage]);
    useEffect(() => {
        allTranslateHandler()
    }, [allTranslateHandler])
    
    useEffect(() => {
        let parseData = {}
        if (assetsnew?.categories?.selectedLanguage) {
            parseData = JSON.parse(assetsnew.categories?.selectedLanguage); // Parse once
        }
        setAllCategories(parseData);
        setAllCategoriesKeys(Object.keys(parseData));

    }, [assetsnew, faqs.length])
    useEffect(() => {
        let parseData = []
        if (assetsnew && assetsnew[tabId]?.selectedLanguage) {
            parseData = JSON.parse(assetsnew[tabId]?.selectedLanguage);
        }
        setFaqs(parseData);
    }, [assetsnew, tabId])
    useEffect(() => {
        let layoutType = null;
        if (assetsnew?.layoutType?.selectedLanguage) {
            layoutType = JSON.parse(assetsnew?.layoutType?.selectedLanguage)
        }
        const findLayout = layoutOption.find(l => l.code === layoutType);

        if (findLayout?.code) {
            setSelectedLayout(findLayout);
        } else {
            setSelectedLayout(layoutOption[0]);
        }
    }, [assetsnew, layoutOption])
   

    const addNewFaq = () => {
        const faq = {
            question: "",
            answer: "",
        }
        setFaqs(prevFaqs => [...prevFaqs, { ...faq }]);
        setAddDisable(true);
    };
    const cancelAddNewFaq = () => {
        setFaqs(prevFaqs => prevFaqs.filter(f => f.question));
        setAddDisable(false);
    };

    const handleLayout = (e) => {
        setSelectedLayout(e.value)
        setTabId('FAQNEW-001')
        setActiveIndex(0);
        submitFaqDataHandler(headers, dispatch, "layoutType", e?.value?.code);
    }
    const createCategory = () => {
        let tab = {};
        let keyVal = `${faqKey}001`;
        if (allCategories) {
            const keyLength = Object.keys(allCategories).length + 1;
            keyVal = faqKey + String(keyLength).padStart(3, "0");
        }
        tab[keyVal] = { name: "", status: 1 };
        setCategory(tab);
        setCategoryKey(keyVal);
        setAddTabStatus(true);
    }
    const submitFaqDataHandler = async (headers, dispatch, key, data, type = '', stateHandler = null) => {
        const request = {
            "faq-new": {
                [key]: JSON.stringify(data)
            }
        }
        setLoading(true);
        const getCMSResponse = (response) => {
            setAddDisable(false);
            setEditKey("")
            setDeleteTabId("")
            if (response.result === 'SUCCESS') {
                if (response.data === 'success') {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Updated successfully' });
                }
                if (key === 'categories') {
                    setAllCategories(data);
                    setAllCategoriesKeys(Object.keys(data));
                }
                if (type === 'save') {
                    stateHandler(data);
                }
                if (type === tabId) {
                    setActiveIndex(0);
                    setTabId('FAQNEW-001');
                }

                allTranslateHandler();
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: error.errorMsg || error.summary });
            }
        };
        uploadTranslations(selectedLanguage?.code, request, headers, dispatch, getCMSResponse);
    };

    const addCategory = () => {
        let categories = { ...allCategories, ...category }
        if(Object.values(categories || {}).filter(item=>item.status === 1).length > 6){
            toast.current.show({ severity: 'error', summary: 'error', detail: 'You can only create up to 6 tabs' });
            return
        }
        setAddTabStatus(false);
        setCategory({ [faqKey]: { name: "" } });
        setCategoryKey(faqKey);
        submitFaqDataHandler(headers, dispatch, "categories", categories);
    }
    const editHandler = () => {
        setEditTabId(null);
        submitFaqDataHandler(headers, dispatch, "categories", { ...allCategories });
    }
    const deleteHandler = () => {
        const id  = deleteTabId;
        if(id){
            deleteModalHandler("", false);
            setAllCategories(prevCategories => {
                const updatedCategories = {
                    ...prevCategories,
                    [id]: {
                        ...prevCategories[id],
                        status: 0
                    }
                }
                submitFaqDataHandler(headers, dispatch, "categories", updatedCategories, id);
                return updatedCategories;
            });
        }
       
    }
    const faqSaveHandler = (faq, index, type = '') => {
        let faqData = [...faqs]
        if (type === 'delete' && faqData[index]) {
            faqData.splice(index, 1);
        } else if (faqs.length) {
            faqData[index] = { ...faq };
        } else {
            faqData = [{ ...faq }];
        }
        submitFaqDataHandler(headers, dispatch, tabId, faqData, 'save', setFaqs);
    }
    const editEnableHandler = (value) => {
        setEditKey(value)
    }
    const swapArrayElements = (arr, fromIndex, toIndex) => {
        if (toIndex < 0 || toIndex >= arr.length) return arr; // Prevent out-of-bounds swap

        const updatedArr = [...arr]; // Create a shallow copy to maintain immutability
        [updatedArr[fromIndex], updatedArr[toIndex]] = [updatedArr[toIndex], updatedArr[fromIndex]]; // Swap elements
        submitFaqDataHandler(headers, dispatch, tabId, updatedArr, 'save', setFaqs);
        return updatedArr;
    };
    const moveDown = (index) => {
        setFaqs(prevFaqs => swapArrayElements(prevFaqs, index, index + 1));
    };

    const moveUp = (index) => {
        setFaqs(prevFaqs => swapArrayElements(prevFaqs, index, index - 1));
    };
    const deleteModalHandler = (tab, visiable) => {
        setDeleteTabModal(visiable);
        setDeleteTabId(tab);
    }

    return (
        <div className='general faq-section'>
            <div className="card">
                <div className='faq-header flex justify-content-between'>
                    <div>
                        <h2>FAQ Management </h2>
                        <p>Create, edit, and organize frequently asked questions to help users find answers quickly.</p>
                    </div>
                    <div className="dropdown flex align-items-center gap-2">
                        <div className="p-inputgroup flex-1 relative">
                            <PR.InputText placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value.toLowerCase())} className='language-search-field' />
                            <span className="pi pi-search"></span>
                        </div>
                        <PR.Dropdown
                            value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.value)}
                            options={languages}
                            optionLabel="name"
                            placeholder="Select a language"
                            disabled={loading || addDisable || editKey}
                        />
                    </div>
                </div>
                <div className="grid grid-nogutter mt-5 dropdown align-items-center">
                    <div className="col-2 paragraph-tag">
                        <p>Layout options <span>-</span></p>
                    </div>
                    <div className="col-10">
                        <PR.Dropdown
                            optionLabel="name"
                            value={selectedLayout}
                            onChange={(e) => handleLayout(e)}
                            options={layoutOption}
                            placeholder="Custom Tab"
                            disabled={loading || addDisable || editKey}
                        />
                    </div>
                </div>
                <PR.Divider />
                <div className='add-edit-faq'>
                    <div className="paragraph-tag flex align-items-center justify-content-between">
                            <h2>Add/Edit FAQ</h2>
                            <div className='flex align-items-center gap-2'>
                            {addTabStatus ? (
                                <div className="flex-item relative">
                                    <PR.InputText
                                        value={category[categoryKey]['name']}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 60) {
                                            setCategory((c) => ({
                                                ...c,
                                                [categoryKey]: { name: e.target.value, status: 1 },
                                            }));
                                            }
                                        }}
                                        placeholder="Enter tab name"
                                        className="category-field"
                                        maxLength={60}
                                    />
                                    {category[categoryKey]['name'] && (
                                    <span onClick={addCategory} className="pi pi-check close-pi tab-pi"></span>
                                    )}
                                </div>
                                ) : null}
                                <div className='flex-item'>
                                    <PR.Button disabled={category[categoryKey]['name'] || loading || addDisable || editKey} label="Add Tab" icon="pi pi-plus" onClick={() => createCategory()} className="add-tab outline-button" />
                                </div>
                            </div>
                        </div>
                    <div className="mt-3 mb-4 align-items-center">
                        <div className="tabs mt-2">
                            <PR.TabView activeIndex={activeIndex} onTabChange={(e) => {
                                setTabId(allCategoriesKeys[e.index]);
                                setEditKey(null);
                                setAddDisable(false);
                                setActiveIndex(e.index);
                                setSearchValue("");
                            }
                            } className='custom-tabs'>
                                {allCategoriesKeys.map((tab, index) => (
                                  allCategories[tab] && allCategories[tab]['status'] === 1 && (
                                    <PR.TabPanel key={tab} disabled={loading || addDisable || editKey} header={
                                        <div className="flex items-center gap-2">
                                            {editTabId === tab ? (
                                                <div className='flex align-items-center'>
                                                   <PR.InputText
                                                        value={allCategories[tab]['name']}
                                                        onChange={(e) => {
                                                            if (e.target.value.length <= 60) {
                                                            setAllCategories((c) => ({
                                                                ...c,
                                                                [tab]: { name: e.target.value, status: 1 },
                                                            }));
                                                            }
                                                        }}
                                                        autoFocus
                                                        className="w-20"
                                                        maxLength={60}
                                                    />
                                                    <div className='edit-delete'>
                                                        <span onClick={() => editHandler()} title="Update Tab Name" className="pi pi-check close-pi"></span>
                                                        <span
                                                            className="pi pi-times close-pi"
                                                            onClick={() => setEditTabId(null)}
                                                            title="Close Edit Tab"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <span className='tab-title'>{allCategories[tab]?.['name']}</span>
                                                    <div className='edit-delete'>
                                                        <span
                                                            className="pi pi-pencil close-pi"
                                                            onClick={() => setEditTabId(tab)}
                                                            title="Edit Tab Name"
                                                        />
                                                        {tab !== "FAQNEW-001" &&
                                                            <span
                                                                className="pi pi-times close-pi"
                                                                onClick={() => { deleteModalHandler(tab, true) }}
                                                                title="Delete Tab"
                                                            />
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    }>

                                    </PR.TabPanel>
                                )))}
                            </PR.TabView>
                            {faqs.map((faq, idx) => (
                                <FaqItem
                                    key={`${tabId}-${idx}-${faq.question}`}
                                    keyVal={`${tabId}-${idx}`}
                                    searchValue={searchValue}
                                    activeIndex={tabId}
                                    faq={faq}
                                    toast={toast}
                                    index={idx}
                                    editKey={editKey}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                    faqSaveHandler={faqSaveHandler}
                                    editEnableHandler={editEnableHandler}
                                    moveUp={moveUp}
                                    moveDown={moveDown}
                                    lastIndex={faqs.length - 1}
                                    loading={loading}
                                    cancelAddNewFaq={cancelAddNewFaq}
                                />
                            ))}
                        </div>
                    </div>

                    {allCategoriesKeys?.length > 0 && <PR.Button
                        label='+ Add New FAQ'
                        className="add-faq-button"
                        onClick={addNewFaq}
                        disabled={(addDisable ? addDisable : editKey) || loading}
                    />}
                </div>
            </div>
            <PR.Toast ref={toast} position="top-right" />
            <PR.Dialog
                    header="Delete Tab"
                    visible={deleteTabModal}
                    draggable={false}
                    resizable={false}
                    dismissableMask={true}
                    onHide={() => deleteModalHandler("", false)}
                    maskClassName='create-voucher-dialog-mask'
                    className="filter-dialog delete-confirm-modal">
                    <div className="delete-confirm-content">
                        <p>Are you sure you want to delete this tab?</p>
                        <div className="text-right">
                            <PR.Button
                                label='Cancel'
                                onClick={()=> deleteModalHandler("", false)}
                                className="outline-button"
                            />
                            <PR.Button
                                label="Delete"
                                className="add-category-btn"
                                onClick={deleteHandler}
                            />
                        </div>
                    </div>
                </PR.Dialog>
        </div>
    );
};
export default FAQ;