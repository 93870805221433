import React, { useState } from 'react';
import * as PR from "../../prime-modules/index";
import { whiteLogo } from "../../assets/images/index";
import "../login/Login.scss";
import { useDispatch } from 'react-redux';
import { adminAuthActions } from '../../store/adminAuth';
import { otpApi } from '../../services/api';

const OTP = ({loginResponse, toast}) => {
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch()

    const handleOTPSubmit = (status) => {
        const getOTPResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(adminAuthActions.onLogin(response?.data));
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                if(status) return
               toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        otpApi({code:otp,passKey:loginResponse?.passkey}, dispatch, getOTPResponse);
    }

    if(!!otp && otp.length === 6){
        handleOTPSubmit(true)
    }
    const customInput = ({events, props}) => <input {...events} {...props} type="text" placeholder="x" className="custom-otp-input" />;
    return (
    <>
        <section className="admin-login-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="login-header flex align-items-center justify-content-between">
                        <PR.Image src={whiteLogo} alt="e-SIM Logo" className="logo-img" />
                        <h1>Backoffice Login</h1>
                    </div>
                    <div className="login-wrapper otp-wrapper">
                        <h6>Please enter the One Time Password (OTP) <br/> received on your email below.</h6>
                            <div className='grid'>
                                <div className="col-12 md:col-12">
                                    <div className="card flex justify-content-center">
                                        <PR.InputOtp value={otp} onChange={(e) => setOtp(e.value)} inputTemplate={customInput} length={6} integerOnly/>
                                    </div>
                                </div>
                                <div className="col-12 mt-3 navigate">
                                    <PR.Button label="Login" disabled={otp.length < 6} onClick={()=>handleOTPSubmit(false)}  className="login-btn w-12" />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default OTP;