import { getAllAssets } from "../genericFunctions/getAssets";
import { globalConfig } from "../GlobalConfig";
import { uploadAssetsApi } from "../services/api";
import { assetDataActions } from "../store/assets";
import moment from "moment";

export const uploadWidthHeightImage = async (file, key, toast, dispatch, headers, screen) => {
  if(file === undefined){
    return true;
  }
  dispatch(dispatch(assetDataActions.disableSave(true)));
  const formData = new FormData();
  formData.append("screen", screen);
  formData.append(key, file);
  const getCMSResponse = (response) => {
    if (response.result === "SUCCESS") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Image updated successfully",
      });
      getAllAssets(toast, dispatch, headers);
    } else if (response.result === "FAILED") {
      const error = response.error;
      if (response.error.summary === 'Request Entity Too Large' || response.status === 413) {
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: "Image size should not exceed 1MB",
        });
      } else {
      toast.current.show({
        severity: error.severity,
        summary: "Error",
        detail: error.errorMsg
          ? error.errorMsg
          : error.summary || "Image updated failed",
      });
    } 
  };
}
  uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
};

export const orderStatuses = [
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "NEW", value: "NEW" },
  { label: "PAYMENT CANCELLED", value: "PAYMENT_CANCELLED" },
  { label: "PAYMENT FAILED", value: "PAYMENT_FAILED" },
  { label: "PAID", value: "PAID"},
  { label: "PURCHASE FAILED", value: "PURCHASE_FAILED" },
  { label: "PURCHASE REVOKED", value: "PURCHASE_REVOKED" },
  { label: "REFUNDED", value: "REFUNDED" },
];
export const paymentGatways = [
  { label: "ApplePay", value: "ApplePay" },
  { label: "Debit / Credit Card", value: "Stripe" },
  { label: "GooglePay", value: "GooglePay" },
  { label: "None", value: "none" },
  { label: "Paypal", value: "Paypal" },
  { label: "Prepaid", value: "prepaid" },
  { label: "PayMongo", value: "PayMongo" }
];

export const handleSubmitFaqData = async (headers, dispatch, data, language) => {
  const formData = new FormData();
  formData.append("screen", "faq");
  formData.append(language, JSON.stringify(data));

  const getCMSResponse = (response) => {
      // if (response.result === 'SUCCESS') {
      //     if (response.data === 'success') {
      //         toast.current.show({ severity: 'success', summary: 'Success', detail: 'Updated successfully' });
      //     }
      //     getAllAssets(toast, dispatch, headers);
      // } else if (response.result === "FAILED" && response.error) {
      //     const error = response.error;
      //     toast.current.show({ severity: error.severity, summary: 'Error', detail: error.errorMsg || error.summary });
      // }
  };

  uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
};

export const modifyDate = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return year !== 1 ? moment(orderDate).format("DD MMM YYYY") : "";
};
export const setPrice = (price) => {
  if (price == null) {
    return "";
  }
  return globalConfig.defaultCurrency + (typeof price === "number" ? setNumberPrecision(price) : price);
};

export const setNumberPrecision = (val) => {
  return val?.toFixed(2);
};

export const formatPromoCode = (promoCode, isVoucherCode = true) => {
  if (!promoCode) {
    return '-';
  }
  return isVoucherCode
    ? promoCode.toUpperCase().match(/.{1,4}/g).join('-')
    : promoCode;
};

export const filterCountryName = (country) => {
  return country ? country.split(" (")[0] : "";
};

export const formatDateTime = (timestamp) => {

  if(!timestamp) {return "-"}
 
  const date = moment(timestamp).format('YYYY-MM-DD');
  if (date === '0001-01-01') {
      return <div>N/A</div>;
  }
  
  return (
      <div>
          {moment(timestamp).format('DD/MM/YYYY')}
          <div className="ml-1">
              {moment(timestamp).format('HH:mm:ss')}
          </div>
      </div>
  );
};

export const setDataAmount = (rowData) => {
  if (rowData?.dataAmount !== undefined) {
    return rowData.dataAmount === -1
      ? rowData.dataAmountForDisplay
      : `${rowData.dataAmount / 1000000000} GB`;
  }
  return "-";
};

const formatDecimals = (num) => {
  const calcDec = Math.pow(10, 1);
  return Math.trunc(num * calcDec) / calcDec;
}

const checkFloatingValue = (formattedData) => {
  return Number.isInteger(formattedData) ? formattedData : formatDecimals(formattedData)
}


export const checkDataSize = (dataSizeInBytes) => {
  const bytesInOneMB = 1000 * 1000; // 1 MB = 1024 KB, 1 KB = 1024 bytes
  const bytesInOneGB = bytesInOneMB * 1000; // 1 GB = 1024 MB
  if (dataSizeInBytes >= bytesInOneGB) {
    // Data is in GB
    const dataSizeInGB = dataSizeInBytes / bytesInOneGB;
    return checkFloatingValue(dataSizeInGB) + " GB";
  } else if (dataSizeInBytes >= bytesInOneMB) {
    // Data is in MB
    const dataSizeInMB = dataSizeInBytes / bytesInOneMB;
    return checkFloatingValue(dataSizeInMB) + " MB";
  } else if(dataSizeInBytes >= 1000) {
    // Data is in bytes
    const dataSizeInMB = dataSizeInBytes / 1000;
    return checkFloatingValue(dataSizeInMB) + " KB";
  } else {
    return 0 + " KB"
  }
};


export const checkProviderCode = (providerCode) => {
  if(providerCode) {
    return !globalConfig?.restrictProviders?.includes(providerCode);
  }
  return true;
};

export const getCurrentTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}-${hours}${minutes}${seconds}`;
};
export const editorConfig = {
  modules: {
      toolbar: {
      container:[
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline"],
          [{ color: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
      ],
      },
      resize: {},
  },
  formats: ["image"],
 };