import React, { useState } from "react";
import * as PR from "../../prime-modules/index";
import { whiteLogo } from "../../assets/images/index";
import * as Yup from 'yup';
import "./Login.scss"; //css
import { useFormik } from "formik";
import { login } from "../../services/api";
import { useDispatch } from 'react-redux';
import { adminAuthActions } from "../../store/adminAuth";
import OTP from '../otp/OTP'

const Login = () => {
    const dispatch = useDispatch()
    const toast = React.useRef(null)
    const [loginResponse, setLoginResponse] = useState(undefined)
    const [loginLoading, setLoginLoading] = useState(false)

    const [initialValues] = React.useState({
        userName: '',
        password: ''
    })
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    })
    const handleSubmit = values => {
        setLoginLoading(true)
        const loginRequest = {
            userName: (values.userName).trim(),
            password: values.password
        }
        const getLoginResponse = (response) => {
            if (response.result === "SUCCESS") {
                if(response.data?.otpEnabled){
                    setLoginResponse(response.data)
                    return
                }
               dispatch(adminAuthActions.onLogin(response.data));

            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoginLoading(false)
        }
        login(loginRequest, dispatch, getLoginResponse);
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <>
               { !loginResponse ?
        <section className="admin-login-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="login-header flex align-items-center justify-content-between">
                        <PR.Image src={whiteLogo} alt="e-SIM Logo" className="logo-img" />
                        <h1>Backoffice Login</h1>
                    </div>
                    <div className="login-wrapper">
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className='grid'>
                                <div className="col-12 md:col-12">
                                    <span className="p-input-icon-left w-12">
                                        <i className="pi pi-user" />
                                        <PR.InputText id="userName" name="userName" placeholder="Username" className="w-12"
                                        value={formik.values.userName} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off"
                                        />
                                    </span>
                                    {formik.errors.userName && formik.touched.userName
                                        ?   <div className="error-message">{formik.errors.userName}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 md:col-12">
                                    <span className="p-input-icon-left password-left w-12">
                                        <PR.Password feedback={false} placeholder="Password" id="password" name="password"  className="w-12" toggleMask
                                        value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off"
                                        />
                                    </span>
                                    {formik.errors.password && formik.touched.password
                                        ?   <div className="error-message">{formik.errors.password}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 mt-3 navigate">
                                    <PR.Button label="Login" disabled={loginLoading} type='submit' className="login-btn w-12" />
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section> : <OTP loginResponse={loginResponse} toast={toast}/>}
         <PR.Toast ref={toast} position="top-right" />
         </>
  );
};

export default Login;