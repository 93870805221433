import React, { useEffect, useState } from "react";
import * as PR from "../../../prime-modules/index";
import moment from "moment";
import QuillResizeImage from "quill-resize-image";
import Quill from "quill";
import { editorConfig } from "../../../utils/reuse";
Quill.register("modules/resize", QuillResizeImage);

const FaqItem = ({ keyVal, faq, index, searchValue, faqSaveHandler, editEnableHandler, editKey, moveUp, moveDown, lastIndex, loading, cancelAddNewFaq }) => {
    const [deleteFaqModal, setDeleteFaqModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(faq.question ? false : true);
    const [question, setQuestion] = useState(faq.question || "");
    const [answer, setAnswer] = useState(faq.answer || "");
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if(!faq.question){
            editEnableHandler(keyVal)
        }
    }, [editEnableHandler, faq.question, keyVal])


    const modules = editorConfig.modules;

    const saveHandler = (index) => {
        let newErrors = {};
        if (!question || !question.trim()) {
            newErrors.question = "Please enter a question";
            setErrors(newErrors);
            return true;
        } else if (!answer || !answer.trim()) {
            newErrors.answer = "Please enter an answer";
            setErrors(newErrors);
            return true;
        }
        setErrors({});
        faqSaveHandler({ question, answer }, index)
        setEnableEdit(false)

    }
    const editHandler = (val) => {
        editEnableHandler(val)
        setEnableEdit(true);
    }
    const cancelHandler = (type = "") => {
        if(type){
            cancelAddNewFaq();
            setEnableEdit(false);
            editEnableHandler("")
        } else {
            editEnableHandler("")
            setEnableEdit(false);
            setQuestion(faq.question || "");
            setAnswer(faq.answer || "");
        }
      
    }
    const deletHandler = (index) => {
        setDeleteFaqModal(false);
        faqSaveHandler({ question, answer }, index, 'delete')
    }
    
    return (
        (faq.question ? (faq.question?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||  faq.answer?.toLowerCase().includes(searchValue.trim().toLowerCase())): true) && (
        <div key={keyVal} className={`faq-block  ${enableEdit ? "faq-editing" : ""} ${loading ? "editor-disable" : ""}`}>
            <div className="grid">
                <div className="col-12">
                    <PR.InputText
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Title (Enter the frequently asked question)"
                        className='title-input-field'
                        disabled={!enableEdit}
                    />
                    {errors.question && <div className='error-msg'>{errors.question}</div>}
                </div>
            </div>
            <div className="grid grid-nogutter mt-1 editor-block">
                <div className="col-12 relative">
                    <PR.Editor
                        key={enableEdit ? enableEdit : keyVal}
                        value={answer}
                        onTextChange={(e) => setAnswer(e.htmlValue)}
                        style={{ height: '300px' }}
                        placeholder='Paragraph (Provide a detailed answer)'
                        readOnly={!enableEdit}
                        modules={modules}
                        className="test"
                    />
                    {errors.answer && <div className='error-msg'>{errors.answer}</div>}
                </div>
            </div>
            <div className="grid grid-nogutter align-items-center buttons-block">
                <div className="col-4">
                    {!!faq?.lastUpdate ? <p>Last Updated: {moment(faq.lastUpdate).format("DD/MM/YYYY | hh:mm:ss")}</p> : null}
                </div>
                <div className="col-8 text-right">
                    {faq.question && <>
                        <PR.Button icon="pi pi-arrow-up" className="outline-button" onClick={() => moveUp(index)} disabled={editKey || index === 0}/>
                        <PR.Button icon="pi pi-arrow-down" className="outline-button" onClick={() => moveDown(index)} disabled={editKey || index === lastIndex}/>
                        <PR.Button
                            label='Delete'
                            className="outline-button"
                            disabled={editKey}
                            onClick={() => setDeleteFaqModal(true)}
                        />
                    </>
                    }
                   { enableEdit && 
                     <PR.Button
                            label='Cancel'
                            className="outline-button"
                            onClick={() => cancelHandler(!faq.question ? 'save' : "")}
                        />}
                    <PR.Button
                        label={enableEdit ? "Save" : "Edit"}
                        className="add-category-btn"
                        disabled={editKey ? editKey !== keyVal : false}
                        onClick={() => enableEdit ? saveHandler(index) : editHandler(keyVal)}
                    />
                </div>
            </div>
            <PR.Dialog
                header="Delete FAQ"
                visible={deleteFaqModal}
                draggable={false}
                resizable={false}
                dismissableMask={true}
                onHide={() => setDeleteFaqModal(false)}
                maskClassName='create-voucher-dialog-mask'
                className="filter-dialog delete-confirm-modal">
                <div className="delete-confirm-content">
                    <p>Are you sure you want to delete this FAQ?</p>
                    <div className="text-right">
                        <PR.Button
                            label='Cancel'
                            onClick={() => setDeleteFaqModal(false)}
                            className="outline-button"
                        />
                        <PR.Button
                            label="Delete"
                            className="add-category-btn"
                            onClick={() => deletHandler(index)}
                        />
                    </div>
                </div>
            </PR.Dialog>

        </div>

    ))
}

export default FaqItem