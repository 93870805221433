import React, { useEffect, useMemo, useState } from 'react';
import General from './general/General';
import Contact from './contact/Contact';
import About from './about/About';
import Home from './home/Home';
import Partner from './partner/Partner';
import Affiliate from './affiliate/Affiliate'
import AdminHeader from '../layout/admin-header';
import AdminFooter from "../layout/admin-footer";
import * as PR from "../../prime-modules/index";
import "./Cms.scss"; //css
import Header from './header/Header';
import LanguageTranslate from './language-translate/LanguageTranslate';
import { getAllAssets } from '../../genericFunctions/getAssets';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Shop from './shop/Shop';
import Shoppartner from './shoppartner/Shoppartner';
import AffiliateShop from './affiliate-shop/AffiliateShop';
import FAQ from './faq/FAQ';
import PolicyComponent from './policy/PolicyComponent';

const Cms = () => {
    const appName = process.env?.REACT_APP_NAME;
    const dispatch = useDispatch();
    const toast = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const allAssets = useSelector(state => state.assetData.cms);
    const disableSave = useSelector(state => state.assetData.disableSave);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const menuItems = [
        {
            label: 'General',
            icon: 'pi pi-fw pi-cog',
            command: () => { },
            disabled: appName === 'data2go-iot' || appName === 'data2go',
        },
        {
            label: 'Header',
            icon: 'pi pi-fw pi-image',
            command: () => { },
            disabled: appName === 'data2go-iot' || appName === 'data2go',
        },
        {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            command: () => { },
            disabled: appName === 'data2go-iot' || appName === 'data2go',
        },
        {
            label: 'Shop Partner',
            icon: 'pi pi-fw pi-home',
            command: () => { },
            disabled: appName !== "data2go",
        },
        {
            label: 'Shop',
            icon: 'pi pi-fw pi-home',
            command: () => { },
            disabled: appName !== "data2go",
        },
        {
            label: 'Shop Affiliate',
            icon: 'pi pi-fw pi-home',
            command: () => { },
            disabled: appName !== "data2go",
        },
        {
            label: 'About Us',
            icon: 'pi pi-fw pi-image',
            command: () => { },
            disabled: appName === 'data2go-iot' || appName === 'data2go',
        },
        {
            label: 'Contact',
            icon: 'pi pi-fw pi-phone',
            command: () => { },
            disabled: appName === 'data2go-iot' || appName === 'data2go',
        },
        
        {
            label: 'Partner',
            icon: 'pi pi-fw pi-users',
            command: () => { },
            disabled: appName === 'esimcrew' || appName === 'esimlinks' ||  appName === 'data2go-iot' || appName === 'data2go',

        },
        {
            label: 'Affiliate',
            icon: 'pi pi-fw pi-user',
            command: () => { },
            disabled: appName === 'esimcrew' ||  appName === 'data2go-iot' || appName === 'data2go',
        },
        {
            label: 'Language Translate',
            icon: 'pi pi-fw pi-language',
            command: () => { },
            disabled: false
        },
        {
            label: 'FAQ',
            icon: 'pi pi-fw pi-question-circle',
            command: () => { },
            disabled: false
        },
        {
            label: 'Policies',
            icon: 'pi pi-fw pi-book',
            command: () => { },
            disabled: false
        }
    ].filter(l => !l.disabled)

    useEffect(() => {
        const fetchAssets = async () => {
          await getAllAssets(toast, dispatch, headers);
        };
      
        fetchAssets();
      }, [dispatch, headers]);
      
    return (
        <div className="main">
            <div className="layout-sidebar">
                <AdminHeader />
            </div>
            <div className="layout-content-wrapper">
                <section className="admin-userlist-section">
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <div className="heading-sec flex align-items-center justify-content-between">
                                <h2>{appName === 'data2go-iot' ? 'Language Translate' : 'Website CMS'}</h2>
                            </div>
                        </div>
                    </div>
                    { allAssets ? <div className="cms-section tabs-section card">
                    <PR.TabMenu model={menuItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                        {menuItems[activeIndex]?.label === 'General' && <General assets={allAssets?.general} disableSave={disableSave} themeColors={allAssets?.themeColors}/> }
                        {menuItems[activeIndex]?.label === 'Header' && <Header assets={allAssets}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Home' && <Home assets={allAssets?.home}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'About Us' && <About assets={allAssets?.aboutUs}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Contact' && <Contact assets={allAssets?.contact} disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Partner' && <Partner assets={allAssets?.partner} disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Affiliate' && <Affiliate assets={allAssets?.affiliate}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Shop Affiliate' && <AffiliateShop assets={allAssets?.['affiliate-new']}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Language Translate' && <LanguageTranslate />}
                        {menuItems[activeIndex]?.label === 'Shop' && <Shop assets={allAssets?.['home-new']}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Shop Partner' && <Shoppartner assets={allAssets?.['branding']}  disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'FAQ' && <FAQ disableSave={disableSave}/>}
                        {menuItems[activeIndex]?.label === 'Policies' && <PolicyComponent assets={allAssets?.policyTerms} disableSave={disableSave}/>}
                        <div className="grid grid-nogutter">
                            <div className="col-4">
                                <label htmlFor="space">&nbsp;</label>
                            </div>
                        </div>
                    </div> : 
                    <div className="col-12 text-center">
                        <div className='progress-spinner'>
                            <PR.ProgressSpinner
                                className="progressSpinner"
                                animationDuration=".5s"
                            />
                        </div>
                    </div>
                    }
                </section>
                <AdminFooter />
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};

export default Cms;
