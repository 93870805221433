import React, { useState, useRef, useMemo, useEffect } from "react";
import * as PR from "../../../prime-modules/index";
import { useSelector, useDispatch } from "react-redux";
import { uploadTranslations } from "../../../services/api"; // API call function
import { getAllAssets } from '../../../genericFunctions/getAssets';
import "./index.scss";
import { editorConfig } from "../../../utils/reuse";

const PolicyComponent = ({assets}) => {
  const appName = process.env?.REACT_APP_NAME;
  const toast = useRef(null);
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const [activeTab, setActiveTab] = useState(0); // 0 = Privacy Policy, 1 = T&C
  const [policyData, setPolicyData] = useState({
        privacyPolicy: assets?.privacyPolicy
          ? JSON.parse(assets.privacyPolicy)
          : { title: "", description: "" },
        termsAndConditions: assets?.termsAndConditions
          ? JSON.parse(assets.termsAndConditions)
          : { title: "", description: "" },
        cookiePolicy: assets?.cookiePolicy
          ? JSON.parse(assets.cookiePolicy)
          : { title: "", description: "" },
  });
            
  const language = "en";

const modules = editorConfig.modules;

  // Handle title input change
  const handleTitleChange = (e, policyType) => {
    setPolicyData((prev) => ({
      ...prev,
      [policyType]: {
        ...prev[policyType],
        title: e.target.value,
      },
    }));
  };

  // Handle editor content change
  const handleEditorChange = (value, policyType) => {
    setPolicyData((prev) => ({
      ...prev,
      [policyType]: {
        ...prev[policyType],
        description: value,
      },
    }));
  };

  // Save Privacy Policy
  const handleSavePrivacy = async () => {
    const { title, description } = policyData.privacyPolicy;

    if (!title.trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Title is a required field.",
      });
      return; 
    } 
    
    if (!String(description || "").trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Description is a required field.",
      });
      return;
    }
    
    const allData = {
        title: policyData.privacyPolicy.title,
        description: policyData.privacyPolicy.description,
    };

    const privacyPolicy = JSON.stringify(allData);
    const request = {policyTerms : {privacyPolicy}}

    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Updated successfully",
        });
      getAllAssets(toast, dispatch, headers);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error?.errorMsg || "Update failed",
        });
      }
    };

    uploadTranslations(language, request, headers, dispatch, getCMSResponse);
  };

  // Save Terms & Conditions
  const handleSaveTerms = async () => {

    const { title, description } = policyData.termsAndConditions;

    if (!title.trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Title is a required field.",
      });
      return; 
    } 
    
    if (!String(description || "").trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Description is a required field.",
      });
      return;
    }
    
    const allData = {
        title: policyData.termsAndConditions.title,
        description: policyData.termsAndConditions.description,
    };

    const termsAndConditions = JSON.stringify(allData);
    const request = {policyTerms : {termsAndConditions}}

    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Updated successfully",
        });
        getAllAssets(toast, dispatch, headers);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error?.errorMsg || "Update failed",
        });
      }
    };

    uploadTranslations(language, request, headers, dispatch, getCMSResponse);
  };

  const handleSaveCookie = async () => {

    const { title, description } = policyData.cookiePolicy;

    if (!title.trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Title is a required field.",
      });
      return; 
    } 
    
    if (!String(description || "").trim()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Description is a required field.",
      });
      return;
    }
    
    const allData = {
        title: policyData.cookiePolicy.title,
        description: policyData.cookiePolicy.description,
    };

    const cookiePolicy = JSON.stringify(allData);
    const request = {policyTerms : {cookiePolicy}}

    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Updated successfully",
        });
        getAllAssets(toast, dispatch, headers);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error?.errorMsg || "Update failed",
        });
      }
    };

    uploadTranslations(language, request, headers, dispatch, getCMSResponse);
  };

  useEffect(() => {
    setPolicyData({
      privacyPolicy: assets?.privacyPolicy
        ? JSON.parse(assets.privacyPolicy)
        : { title: "", description: "" },
      termsAndConditions: assets?.termsAndConditions
        ? JSON.parse(assets.termsAndConditions)
        : { title: "", description: "" },
      cookiePolicy: assets?.cookiePolicy
        ? JSON.parse(assets.cookiePolicy)
        : { title: "", description: "" },
    });
  }, [assets, activeTab]);  

  return (
    <div className="policy-container">
      <PR.Toast ref={toast} />
      <PR.TabView
        className="content-tabs"
        activeIndex={activeTab}
        onTabChange={(e) => {
          setActiveTab(e.index);
        }}
      >
        {/* Privacy Policy Tab */}
        <PR.TabPanel header="Privacy Policy">
          <div className="grid input-field align-items-center mb-2">
              <div className="col-12">
                <PR.InputText
                  value={policyData.privacyPolicy.title}
                  onChange={(e) => handleTitleChange(e, "privacyPolicy")}
                  placeholder="Enter title"
                />
              </div>
          </div>
          <PR.Editor
            name="privacyPolicy"
            value={policyData.privacyPolicy.description}
            onTextChange={(e) =>
              handleEditorChange(e.htmlValue, "privacyPolicy")
            }
            style={{ height: "450px" }}
            placeholder="Enter Privacy Policy details"
            modules={modules}
          />
          <div className="button-container mt-3">
            <PR.Button
              label="Save"
              onClick={handleSavePrivacy}
              className="save-button"
            />
          </div>
        </PR.TabPanel>

        {/* Terms & Conditions Tab */}
        <PR.TabPanel header="Terms & Conditions">
          <div className="grid input-field align-items-center mb-2">
              <div className="col-12">
                <PR.InputText
                  value={policyData.termsAndConditions.title}
                  onChange={(e) => handleTitleChange(e, "termsAndConditions")}
                  placeholder="Enter title"
                />
              </div>
          </div>
          <PR.Editor
            name="termsAndConditions"
            value={policyData.termsAndConditions.description}
            onTextChange={(e) =>
              handleEditorChange(e.htmlValue, "termsAndConditions")
            }
            style={{ height: "450px" }}
            placeholder="Enter Terms & Conditions details"
            modules={modules}
          />
          <div className="button-container mt-3">
            <PR.Button
              label="Save"
              onClick={handleSaveTerms}
              className="save-button"
            />
          </div>
        </PR.TabPanel>

        {/* Cookie Policy Tab */}
        {(appName === 'esimple' || appName === 'data2go' || appName === 'data2go-iot') &&
        <PR.TabPanel header="Cookie Policy">
          <div className="grid input-field align-items-center mb-2">
              <div className="col-12">
                <PR.InputText
                  value={policyData.cookiePolicy.title}
                  onChange={(e) => handleTitleChange(e, "cookiePolicy")}
                  placeholder="Enter title"
                />
              </div>
          </div>
          <PR.Editor
            name="cookiePolicy"
            value={policyData.cookiePolicy.description}
            onTextChange={(e) =>
              handleEditorChange(e.htmlValue, "cookiePolicy")
            }
            style={{ height: "450px" }}
            placeholder="Enter Cookie Policy details"
            modules={modules}
          />
          <div className="button-container mt-3">
            <PR.Button
              label="Save"
              onClick={handleSaveCookie}
              className="save-button"
            />
          </div>
        </PR.TabPanel>
        }
      </PR.TabView>
    </div>
  );
};

export default PolicyComponent;
