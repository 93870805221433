import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate, useParams } from 'react-router-dom';
import "../users/Users.scss"; // CSS
import { fraudulentAPI, getOrderDetails, networkInfo, refundAPI, retryOrderStatus } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import * as utils from '../../utils';
import Notes from "../notes/Notes";
import { formatPromoCode, orderStatuses, formatDateTime } from "../../utils/reuse";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

const OrderDetails = () => {
    const appName = process.env?.REACT_APP_NAME;
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef(null)
    const menuRef = useRef(null);
    const [orders, setOrders] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [networkInfoLoading, setNetworkInfoLoading] = useState(true);
    const [fraudLoading, setFraudLoading] = useState(false);
    const [dialogState, setDialogState] = useState({
        orderInfoVisible: false,
        otherInfoVisible: false,
        qrCodeVisible: false,
        fraudVisible: false,
        refundVisible: false
    });
    const [networkDetails, setNetworkDetails] = useState({})
    const [refresh, setRefresh] = useState(false);

    const app_name = process.env?.REACT_APP_NAME

    const getNetworkInfo = useCallback(() => {
        setNetworkInfoLoading(true)
        const networkDetails = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data) {
                    setNetworkDetails(response.data);
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No Network Details available' });
                }
            }
            else {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setNetworkInfoLoading(false);
        }

        networkInfo(dispatch, headers, orders.iccid, networkDetails)
    }, [dispatch, headers, orders.iccid])

    const showDialog = useCallback((type) => {
        if (type === 'otherInfo') {
            getNetworkInfo();
        }
        setDialogState((prevState) => ({
            ...prevState,
            [`${type}Visible`]: true
        }));
    }, [getNetworkInfo]);

    const hideDialog = useCallback((type) => {
        setDialogState((prevState) => ({
            ...prevState,
            [`${type}Visible`]: false
        }));
    }, []);

    const copyToClipboard = (text, message = 'Order ID') => {
        navigator.clipboard.writeText(text).then(() => {
            toast.current?.show({ severity: 'success', summary: 'Success', detail: `${message} has been copied to clipboard.` });
        }).catch(err => {
            toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Failed to copy.' });
        });
    };
    const eSIMCompatibility = (isEsimCompatible) => {
        toast.current?.show({ severity: isEsimCompatible ? 'success' : 'error', summary: 'Info', detail: isEsimCompatible ? `Purchase was made on a device that supports eSIM` : `Purchase was made on a device that does not support eSIM` })
    }

    const menuItems = [
        {
            label: 'Mark as Fraudulent',
            command: () => showDialog('fraud'),
            disabled: orders?.orderStatus !== 'COMPLETED',
            enabled: true,
        },
        {
            label: 'Refund Payment',
            command: () => showDialog('refund'),
            disabled: orders?.transactionStatus !== 'COMPLETED',
            enabled: true,
        },
        {
            label: 'QR Code',
            command: () => showDialog('qrCode'),
            disabled: !orders?.iccid,
            enabled: appName !== 'data2go-iot',
        },
        {
            label: 'Network Info',
            command: () => showDialog('otherInfo'),
            disabled: !orders?.iccid,
            enabled: appName !== 'data2go-iot',
        }
    ].filter(m => m.enabled);

    const items = [
        {
            label: params['screen'] === 'orders' ? 'Orders' : 'Customers',
            command: () => {
                params['screen'] === 'orders'
                    ? navigate('/orders')
                    : navigate('/users');
            }
        },
        {
            label: 'Orders',
            command: () => {
                params['screen'] === 'orders'
                    ? navigate('/orders')
                    : navigate('/user-orders/' + orders.userId);
            },
            visible: params['screen'] === 'user-orders'
        },
        {
            label: params.orderid,
            disabled: true,
            visible: params['screen'] === 'orders',
        },
        {
            template: (
                <PR.Button
                    icon="pi pi-copy"
                    className="p-button-text p-button-sm ml-20 icon-button"
                    onClick={() => copyToClipboard(orders.orderId)}
                    tooltip="Copy Order ID"
                    tooltipOptions={{ position: 'top' }}
                />
            ),
            visible: params['screen'] === 'orders',
        }
    ];

    const home = { icon: 'pi pi-home', url: '/' }

    const orderDetails = useCallback(() => {
        setLoading(true);
        const reqObj = {
            orderId: params.orderid
        }
        const detailsResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data && response.data.orderId) {
                    setOrders(response.data);
                    setError(false);
                } else {
                    setError(true);
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No Order Details available' });
                }
            }
            else {
                setError(true);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        }

        getOrderDetails(reqObj, headers, dispatch, detailsResponse)
    }, [dispatch, headers, params.orderid]);


    useEffect(() => {
        orderDetails();
    }, [orderDetails, refresh])

    const orderStatus = (status) => {
        const statusVal = orderStatuses?.filter(val => val.value === status)[0]?.label;
        return (
            <span
                className={`status ${status?.toLowerCase()}`}
            >{statusVal ?? status ?? 'N/A'}</span>
        );
    };

    const priceTemplate = rowData => {
        return (
            <div className="price tooltip-wrapper">
                {orders?.dataPlans[0]?.isSubscription ? <div >
                    {utils.setPrice(rowData.dataPlans[0].price)}
                </div> : <>
                    {rowData.priceBeforeDiscount > 0 && (rowData.priceBeforeDiscount !== rowData.totalOrderAmount) && (
                        <>
                            <s className="red-color" {...(rowData.promoCode && {
                                'data-title': `${orders.paymentGateway === 'prepaid' ? 'Voucher Code' : 'Promocode'}: ${formatPromoCode(rowData.promoCode, (orders.paymentGateway === 'prepaid'))}`
                            })}
                            >
                                {utils.setPrice(rowData.priceBeforeDiscount)}
                            </s>
                        </>
                    )}
                    <div
                        {...(rowData.promoCode && { 'data-title': `${orders.paymentGateway === 'prepaid' ? 'Voucher Code' : 'Promocode'}: ${formatPromoCode(rowData.promoCode, (orders.paymentGateway === 'prepaid'))}` })}>
                        {utils.setPrice(rowData.totalOrderAmount)}
                    </div>
                </>}
            </div>
        )
    };

    const geteSIMsDescription = (esim) => {
        if (!!orders?.dataPlans && orders?.dataPlans.length === 0) {
            return '-'
        }
        if (!!esim && orders?.dataPlans[0]?.isSubscription) {
            return esim?.description?.split(" ")[0] + "_" +esim?.dataAmountForDisplay
        }
        const countryName = esim?.countries?.length ? esim?.countries[0]?.country?.name?.split("(")[0] : '';
        const description = countryName && esim.dataAmountForDisplay ? `${countryName} - ${esim.dataAmountForDisplay}` : esim.description;
        return description;
    }

    const validationSchema = Yup.object().shape({
        reason: Yup.string()
            .required("Reason is required")
            .min(3, "Reason must be at least 3 characters")
            .max(1000, "Reason must be at most 1000 characters"),
    });

    const fraudulentSumbitHandler = (values, { resetForm }) => {
        const { reason } = values;
        if (reason.trim()) {
            setFraudLoading(true);
            const request = { reason };
            const response = (response) => {
                setFraudLoading(false);
                hideDialog('fraud');
                if (response.result === "SUCCESS") {
                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Fraudulent activity has been reported successfully.",
                    });
                    setRefresh(!refresh);
                    resetForm();
                } else if (response.result === "FAILED" && response.error) {
                    const error = response.error;
                    toast.current?.show({
                        severity: error.severity,
                        summary: "Error",
                        detail: error.errorMsg ? error.errorMsg : error.summary,
                    });
                }
            };
            fraudulentAPI(request, dispatch, headers, orders.orderId, response);
        }
    }
    const refundSubmitHandler = (values, { resetForm }) => {
        const { reason } = values;
        if (reason.trim()) {
            setFraudLoading(true);
            const request = { reason };
            const response = (response) => {
                setFraudLoading(false);
                hideDialog('refund');
                if (response.result === "SUCCESS") {
                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Refund has been successfully processed..",
                    });
                    setRefresh(!refresh);
                    resetForm();
                } else if (response.result === "FAILED" && response.error) {
                    const error = response.error;
                    toast.current?.show({
                        severity: error.severity,
                        summary: "Error",
                        detail: error.errorMsg ? error.errorMsg : error.summary,
                    });
                }
            };
            refundAPI(request, dispatch, headers, orders.orderId, response);
        }
    }

    const discountType = (rowData) => {
        return rowData.promoCode && <div>
            <span>{formatPromoCode(rowData?.promoCode, (rowData.paymentGateway === 'prepaid'))}</span>
            <i
                title={rowData.paymentGateway === 'prepaid' ? 'Copy Voucher Code' : 'Copy Promocode'}
                className="pi pi-copy"
                style={{ color: 'red', marginLeft: '8px', cursor: 'pointer' }}
                onClick={() => copyToClipboard(formatPromoCode(rowData?.promoCode, (rowData.paymentGateway === 'prepaid')), (rowData.paymentGateway === 'prepaid' ? 'Voucher Code' : 'Promocode'))}
            />
        </div>
    }

    const transactionStatus = (rowData) => {
        return (<span
            className={`status-order ${rowData?.transactionStatus?.toLowerCase()}`}
        >{rowData?.transactionStatus ?? 'N/A'}</span>)
    }

    const paymentId = (rowData) => (
        <p className="payment-id-column">{rowData?.paymentMethodId}</p>
    );

    const transactionId = (rowData) => (
        <p className="payment-id-column">{rowData?.transactionId}</p>
    );

    const handleEsimNavigation = (iccid) => {
        if (!iccid) {
            return
        }
        const newTabUrl = `/esim-details/${iccid}`;
        window.open(newTabUrl, '_blank');
    }

   const handleRetryOrderAPI = () => {
      const saveNotesResponse = (response) => {
        if (response.result === "SUCCESS") {
        orderDetails()
        toast.current?.show({ severity: 'success', summary: 'Success', detail: `Retry order was successfully processed.` });
        } else if (response.result === "FAILED" && response.error) {
          const error = response.error;
          toast.current?.show({
            severity: error.severity,
            summary: "Error",
            detail: error.errorMsg ? error.errorMsg : error.summary,
          });
        }
      };
      retryOrderStatus(dispatch, headers, orders.orderId, saveNotesResponse);

  };

    return (
        <>
            <div className="main">
                <PR.Toast ref={toast} position="top-right" />
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section order-details-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec flex align-items-center mb-2">
                                    <h1>Orders Details</h1>
                                    <div className="breadcrumb-section mobile-breadcrumb">
                                        <PR.BreadCrumb model={items} home={home} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!loading ?
                            !error ?
                                <>
                                    <div className="users-data-table card">
                                        <div className="grid order-details-sec">
                                            <div className="col-12 md:col-12 list-box">
                                                <div className="flex align-items-center justify-content-between mobile-flex">
                                                    <div className="left ">
                                                        <div>
                                                            <b className="text-lg">ICCID: </b>
                                                            <b id="iccidTooltip" onClick={() => handleEsimNavigation(orders?.iccid)} className={orders?.iccid ? "iccid-tag text-lg" : "custom-target-icon"}>{orders.iccid ? orders.iccid : '-'} {orders.tag ? '(' + orders.tag + ')' : ''}</b>
                                                            <PR.Tooltip
                                                            target="#iccidTooltip"
                                                            content={orders?.iccid ? `View eSIM & bundle details` : "No ICCID available"}
                                                            position="bottom"
                                                            />
                                                        </div>
                                                        <p className="text-gray-600 mt-1 text-sm">Short Order ID: {(orders.orderId.substring(0, 8))}</p>
                                                    </div>
                                                    <div className="right flex align-items-center gap-4">
                                                        <div className="flex gap-1 justify-content-between">
                                                            <p>Order Status: {orderStatus(orders.orderStatus)}</p>
                                                            <div className="notes-header">
                                                                <PR.Button
                                                                    disabled={orders.orderStatus !== "PAYMENT_FAILED" && orders.orderStatus !== "PURCHASE_FAILED" }
                                                                    icon="pi pi-refresh"
                                                                    className="p-button-sm p-button-secondary"
                                                                    tooltip="Retry Payment"
                                                                    tooltipOptions={{ position: 'bottom' }}
                                                                    onClick={() => handleRetryOrderAPI()}
                                                                />
                                                            </div>                     
                                                        </div>
                                                       
                                                        <div className="flex gap-3 justify-content-between">
                                                           {app_name === 'data2go-iot'? <p>Subscription: {orders.subscriptionStatus ? <span className={"statusFields " + orders.subscriptionStatus}>{orders.subscriptionStatus}</span> : '-'}</p> : <p>eSIM Status: {orders.esimStatus ? <span className={"statusFields " + orders.esimStatus}>{orders.esimStatus}</span> : '-'}</p>}
                                                        </div>
                                                        <div className="flex gap-3 justify-content-between1">
                                                            <PR.Button
                                                                icon="pi pi-copy"
                                                                onClick={() => copyToClipboard(orders.orderId)}
                                                                className="icon-button"
                                                                tooltip="Copy Order ID"
                                                                tooltipOptions={{ position: 'top' }}
                                                                style={{ color: 'red' }}
                                                            />
                                                            <PR.Button
                                                                icon="pi pi-info-circle"
                                                                className="icon-button"
                                                                onClick={() => eSIMCompatibility(orders.isEsimCompatible)}
                                                            />
                                                            <PR.Button
                                                                icon="pi pi-ellipsis-h"
                                                                className="icon-button"
                                                                onClick={(e) => menuRef.current.toggle(e)}
                                                            />
                                                            <PR.Menu
                                                                model={menuItems}
                                                                popup
                                                                ref={menuRef}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Modal Dialog */}

                                                <PR.Dialog
                                                    header="QR Code"
                                                    visible={dialogState.qrCodeVisible}
                                                    draggable={false}
                                                    resizable={false}
                                                    style={{
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        position: 'absolute',
                                                        margin: '0'
                                                    }}
                                                    onHide={() => hideDialog('qrCode')}
                                                    dismissableMask={true}
                                                    className="p-dialog-centered information-dialog qrcode-dialog">

                                                    <div className="qr-code">
                                                        <QRCodeCanvas value={orders.qrCode} size={256} />
                                                    </div>

                                                </PR.Dialog>
                                                <PR.Dialog
                                                    header="Mark as Fraudulent"
                                                    visible={dialogState.fraudVisible}
                                                    style={{
                                                        width: '35vw',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        position: 'absolute',
                                                        margin: '0'
                                                    }}
                                                    onHide={() => hideDialog('fraud')}
                                                    draggable={false}
                                                    resizable={false}
                                                    dismissableMask={true}
                                                    modal
                                                    className="p-dialog-centered information-dialog">
                                                    {fraudLoading ? (
                                                        <div className="flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                                            <PR.ProgressSpinner animationDuration=".5s" />
                                                        </div>
                                                    ) : (<Formik
                                                        initialValues={{ reason: "" }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={fraudulentSumbitHandler}
                                                    >
                                                        {({ resetForm }) => (
                                                            <Form className="p-mb-2 add-notes mb-3">
                                                                <div>
                                                                    <Field
                                                                        name="reason"
                                                                        as={PR.InputTextarea}
                                                                        placeholder="Enter your reason"
                                                                        className="p-mr-2"
                                                                        autoResize
                                                                        rows={4}
                                                                        style={{
                                                                            width: "100%",
                                                                            minHeight: "100px",
                                                                            maxHeight: "300px",
                                                                        }}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="reason"
                                                                        component="div"
                                                                        className="p-error text-sm"
                                                                    />
                                                                </div>
                                                                <div className="p-mt-2 text-right mt-2">
                                                                    <PR.Button
                                                                        type="submit"
                                                                        label="Save"
                                                                        className="p-button save-button"
                                                                    />
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>)}

                                                </PR.Dialog>
                                                <PR.Dialog
                                                    header="Refund Payment"
                                                    visible={dialogState.refundVisible}
                                                    style={{
                                                        width: '35vw',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        position: 'absolute',
                                                        margin: '0'
                                                    }}
                                                    onHide={() => hideDialog('refund')}
                                                    draggable={false}
                                                    resizable={false}
                                                    dismissableMask={true}
                                                    modal
                                                    className="p-dialog-centered information-dialog">
                                                    {fraudLoading ? (
                                                        <div className="flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                                            <PR.ProgressSpinner animationDuration=".5s" />
                                                        </div>
                                                    ) : (<Formik
                                                        initialValues={{ reason: "" }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={refundSubmitHandler}
                                                    >
                                                        {({ resetForm }) => (
                                                            <Form className="p-mb-2 add-notes mb-3">
                                                                <div>
                                                                    <Field
                                                                        name="reason"
                                                                        as={PR.InputTextarea}
                                                                        placeholder="Enter your reason"
                                                                        className="p-mr-2"
                                                                        autoResize
                                                                        rows={4}
                                                                        style={{
                                                                            width: "100%",
                                                                            minHeight: "100px",
                                                                            maxHeight: "300px",
                                                                        }}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="reason"
                                                                        component="div"
                                                                        className="p-error text-sm"
                                                                    />
                                                                </div>
                                                                <div className="p-mt-2 text-right mt-2">
                                                                    <PR.Button
                                                                        type="submit"
                                                                        label="Save"
                                                                        className="p-button save-button"
                                                                    />
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>)}

                                                </PR.Dialog>

                                                <PR.Dialog
                                                    header="Network Information"
                                                    visible={dialogState.otherInfoVisible}
                                                    style={{
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        position: 'absolute',
                                                        margin: '0'
                                                    }}
                                                    onHide={() => hideDialog('otherInfo')}
                                                    draggable={false}
                                                    resizable={false}
                                                    dismissableMask={true}
                                                    modal
                                                    className="p-dialog-centered information-dialog"
                                                >
                                                    {networkInfoLoading ? (
                                                        <div className="flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                                            <PR.ProgressSpinner animationDuration=".5s" />
                                                        </div>
                                                    ) : (
                                                        networkDetails && (networkDetails?.mobileNetworkCode || networkDetails?.networkName || networkDetails?.country || networkDetails?.lastSeen) ? (
                                                            <div className="info-block">
                                                                <ul className="flex align-items-center list-none p-0">
                                                                    <li>Mobile Network Code <span>:</span></li>
                                                                    <li>{networkDetails?.mobileNetworkCode}</li>
                                                                </ul>
                                                                <ul className="flex align-items-center list-none p-0">
                                                                    <li>Network Name <span>:</span></li>
                                                                    <li>{networkDetails?.networkName}</li>
                                                                </ul>
                                                                <ul className="flex align-items-center list-none p-0">
                                                                    <li>Country <span>:</span></li>
                                                                    <li>{networkDetails?.country}</li>
                                                                </ul>
                                                                <ul className="flex align-items-center list-none p-0">
                                                                    <li>Last seen <span>:</span></li>
                                                                    <li>{networkDetails?.lastSeen}</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-content-center align-items-center" style={{ minHeight: '100px' }}>
                                                                <span>No Information Available</span>
                                                            </div>
                                                        )
                                                    )}
                                                </PR.Dialog>

                                                {/* Modal Dialog */}
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <PR.DataTable
                                                    value={[{ ...orders }]}
                                                    rows={10} className="plan-detials-datatable">
                                                    <PR.Column body={orders.providerName} header="Provider Name" style={{ width: "10%" }}></PR.Column>
                                                    <PR.Column body={geteSIMsDescription(orders?.dataPlans.length && orders.dataPlans[0])} header="Plan" style={{ width: "15%" }}></PR.Column>
                                                    {app_name === 'data2go-iot' ?  null : ( 
                                                        <PR.Column body={() => (
                                                            orders?.dataPlans.length ? (
                                                            <div>
                                                                <div>{orders.dataPlans[0].name}</div>
                                                                <div style={{ marginTop: "4px"}}>
                                                                {orders.dataPlans[0].groups}
                                                                </div>
                                                            </div>
                                                            ) : "-"
                                                        )}  
                                                            header="Bundle Name" style={{ width: "10%" }}>
                                                        </PR.Column>
                                                    )}
                                                    <PR.Column
                                                        header="Order Type"
                                                        body={orders?.dataPlans.length ? orders?.dataPlans[0]?.isSubscription ? "Recurring" : orders?.istopup === "true" ? "Topup" : "Regular" : "-"}
                                                        style={{ width: "10%" }}
                                                    />
                                                    <PR.Column
                                                        header="Purchased On"
                                                        body={formatDateTime(orders.createdTs)}
                                                        style={{ width: "10%" }}
                                                    />
                                                    {orders?.dataPlans[0]?.isSubscription ? <PR.Column
                                                        header="Expiry/Renewal date"
                                                        body={formatDateTime(orders?.nextRenewalDate)}
                                                        style={{ width: "10%" }}
                                                    /> : <PR.Column
                                                        header="Expiry On"
                                                        body={formatDateTime(orders?.bundleEndTime)}
                                                        style={{ width: "10%" }}
                                                    />}
                                                    <PR.Column header="Price" body={priceTemplate} style={{ width: "10%" }}></PR.Column>
                                                </PR.DataTable>
                                            </div>
                                        </div>

                                        <PR.Tooltip target=".price" />

                                        <div className="grid order-details-sec">
                                            <div className="col-12 md:col-6">
                                                <div className="list-box">
                                                    <PR.Panel header="Customer Details">
                                                        <ul className="user-data-ul">
                                                            {(orders.contactInfo?.firstName || orders.contactInfo?.lastName) && <li><span>Name : </span> <span className="user-data-span">{orders.contactInfo?.firstName} {orders.contactInfo?.lastName}</span></li>}
                                                            {orders.contactInfo?.email && <li><span>Email : </span> <span className="user-data-span">{orders.contactInfo?.email} </span></li>}
                                                            <li><span>Phone Number : </span> <span className="user-data-span">{!!orders.contactInfo?.phone?.localPhoneNumber ? orders.contactInfo?.phone?.callingCode + " " + orders.contactInfo?.phone?.localPhoneNumber : '-'}</span>                                                            </li>
                                                            <li><span>IP Address : </span> {orders.ipAddress ? ( <span className="user-data-span">{orders?.ipAddress} </span> ) : ( <span className="user-data-span"> - </span>)}</li>
                                                            
                                                            {orders.paymentGateway === 'Stripe' && <>
                                                                {orders.billingAddress?.address && <li><span>House No/Building : </span> <span className="user-data-span">{orders.billingAddress?.address} </span> </li>}
                                                                {orders.billingAddress?.street && <li><span>Street : </span> <span className="user-data-span">{orders.billingAddress?.street}</span> </li>}
                                                                {orders.billingAddress?.city && <li><span>City : </span><span className="user-data-span">{orders.billingAddress?.city}</span> </li>}
                                                                {orders.billingAddress?.city && <li><span>Postcode : </span><span className="user-data-span"> {orders.billingAddress?.postCode}</span> </li>}
                                                                {orders.billingAddress?.country && <li><span>Country : </span> <span className="user-data-span">{orders.billingAddress?.country}</span> </li>}
                                                            </>}
                                                        </ul>
                                                    </PR.Panel>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="list-box">
                                                    <Notes orderId={orders.orderId} refresh={refresh} />
                                                </div>
                                            </div>
                                        </div>
                                        {!!orders?.subscriptionDetails?.transactions ? <div className="grid order-details-sec">
                                            <p className="ml-2 transaction-heading-p">Transaction history</p>
                                            <div className="col-12 md:col-12 -mt-3">
                                                {orders?.dataPlans[0]?.isSubscription ? <PR.DataTable  className="transaction-history-datatable"
                                                    value={orders?.subscriptionDetails?.transactions} rows={10} >
                                                    <PR.Column className="no-highlight" field="transactionId" sortable body={(rowData) => rowData?.transactionId} header="Transaction ID" style={{ width: "16%" }} />
                                                    <PR.Column className="no-highlight" field="paymentMethodId" sortable body={(rowData) => rowData?.paymentMethodId} header="Payment Method ID" style={{ width: "18%" }} />
                                                    <PR.Column className="no-highlight" field="totalOrderAmount" sortable header="Value" body={(rowData) => utils.setPrice(rowData?.totalOrderAmount)} style={{ width: "8%" }} />
                                                    <PR.Column className="no-highlight" field="paymentGateway" sortable header="Payment Gateway" body={(rowData) => rowData?.paymentGateway} style={{ width: "10%" }} />
                                                    <PR.Column className="no-highlight" field="createdTs" sortable header="Date" body={(rowData) => formatDateTime(rowData?.createdTs)} style={{ width: "8%" }} />
                                                    <PR.Column className="no-highlight" field="transactionStatus" sortable header="Transaction status" body={transactionStatus} style={{ width: "10%" }} />
                                                </PR.DataTable>
                                                    : <PR.DataTable value={[{ ...orders }]} rows={10} className="transaction-history-datatable">
                                                        <PR.Column className="no-highlight" field="transactionId" sortable body={transactionId} header="Transaction ID" style={{ width: "16%" }} />
                                                        <PR.Column className="no-highlight" field="paymentMethodId" body={paymentId} sortable header="Payment Method ID" style={{ width: "18%" }} />
                                                        <PR.Column className="no-highlight" field="totalOrderAmount" sortable header="Value" body={(rowData) => utils.setPrice(rowData?.totalOrderAmount)} style={{ width: "8%" }} />
                                                        <PR.Column className="no-highlight" field="paymentGateway" sortable header="Payment Gateway" body={(rowData) => rowData?.paymentGateway ? ((rowData.paymentGateway === "Stripe") ? "Debit / Credit Card" : utils.firstLetterCapital(rowData.paymentGateway)) : '-'} style={{ width: "10%" }} />
                                                        {orders.promoCode ? <PR.Column className="no-highlight promo-voucher" field="promoCode" sortable header={orders.paymentGateway === 'prepaid' ? 'Voucher Code' : 'Promocode'} style={{ width: "10%" }} body={(rowData) => discountType(rowData)} /> : null}
                                                        <PR.Column className="no-highlight" field="createdTs" sortable header="Date" body={(rowData) => formatDateTime(rowData?.createdTs)} style={{ width: "8%" }} />
                                                        <PR.Column className="no-highlight tstatus" field="transactionStatus" sortable header="Transaction status" body={transactionStatus} style={{ width: "10%" }} />
                                                    </PR.DataTable>}

                                            </div>
                                        </div> : null}

                                    </div>
                                </> :
                                <section className="admin-users-section">
                                    <div className="col-12 text-center">
                                        <h6 className="font-semibold text-lg"> No details found</h6>
                                    </div>
                                </section> : ''
                        }
                        {loading && (
                            <div className="col-12 text-center">
                                <div className="progress-spinner">
                                    <PR.ProgressSpinner
                                        className="progressSpinner"
                                        animationDuration=".5s"
                                    />
                                </div>
                            </div>
                        )}
                    </section>
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default OrderDetails;